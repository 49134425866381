import React, { useState,} from "react";
import { useSelector} from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../Styles/components/AppTopbar.scss";
import { useTranslation } from "react-i18next";
import i18n from "../Config/i18n";
import { Avatar } from "primereact/avatar";
import { removeAuthToken } from "../Service/api";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ProgressSpinner } from 'primereact/progressspinner';
import "../Styles/pages/profile.scss";
import logo from "../assets/images/Reveknew_Logo_All_White.png"
import defaultAvatar from "../../src/assets/images/default avatar.png"
import { useKeycloak } from "@react-keycloak/web";

const AppTopbar = ({
	onMenuButtonClick,
	onTopbarMenuButtonClick,
	onTopbarItemClick,
	topbarMenuActive,
	activeTopbarItem,
}) => {
	const onTopbarItemClickIn = (event, item) => {
		if (onTopbarItemClick) {
			onTopbarItemClick({
				originalEvent: event,
				item: item,
			});
		}
	};

	let topbarItemsClassName = classNames("topbar-menu fadeInDown", {
		"topbar-menu-visible": topbarMenuActive,
	});

	const { t } = useTranslation("common");

	const navigate = useNavigate();
	const { keycloak } = useKeycloak();

	const defMembership = useSelector((state) => state.defMembership.defMembership);
	const profile = useSelector((state) => state.profile.profile);

	const menuItemsNoColleagues = [
		{
			name: t('nav.user_profile'),
			icon: "pi pi-user",
			onClick: () => {
				navigate("/user/profile")
			},
		},
		{
			name: t('nav.logout'),
			icon: "pi pi-sign-out",
			onClick: () => logoutFn()
		},
	];

	const menuItems = [
		{
			name: t('nav.colleagues'),
			icon: "pi pi-user-plus",
			onClick: () => {
				navigate("teams")
			},
		},
		{
			name: t('settings'),
			icon: "pi pi-cog",
			onClick: () => {
				navigate("settings")
			},
		},
		...menuItemsNoColleagues,
	];

	const { firstName, lastName } = profile ? profile : ''

	const { email } = profile ? profile : ''

	const { imageUrl } = defMembership ? defMembership?.user : ""

	const { role } = defMembership ? defMembership : { role: "" };

	const { purpose, type } = defMembership ? defMembership?.business : ""

	const logoutFn = async () => {
		
		localStorage.setItem('dialogClosed', JSON.stringify(false));
		removeAuthToken()
		keycloak.logout({
			redirectUri: process.env.REACT_APP_AUTH_LOGOUTURL, 
		  });
		sessionStorage.setItem('crumbs',[])
	};

	return (
		<div className="layout-topbar clearfix" style={{ backgroundColor: "#D0021B" }}>

			<button className="layout-topbar-logo p-link">
				<img id="layout-topbar-logo" alt="babylon-layout" src={logo} />
			</button>

			<button className="layout-menu-button p-link" onClick={onMenuButtonClick}>
				<i className="pi pi-bars" />
			</button>

			<button
				id="topbar-menu-button"
				className="p-link"
				onClick={onTopbarMenuButtonClick}
			>
				<i className="pi pi-ellipsis-v" />
			</button>

			<ul className={topbarItemsClassName}>
				<li
					className={classNames("user-profile", {
						"active-topmenuitem": activeTopbarItem === "profile",
					})}
					onClick={(e) => onTopbarItemClickIn(e, "profile")}
				>
					<button className="p-link">
						{!defMembership || !profile ?
							<ProgressSpinner style={{ width: '35px', height: '35px', color: 'whitesmoke', marginTop: '0.3rem' }} strokeWidth="8" fill="#666" animationDuration=".8s" />
							:
							<>
								{!imageUrl ? (
									<Avatar
										label={firstName ? firstName[0]?.toUpperCase() : ""}
										shape="circle"
										size="small"
										style={{ backgroundColor: "#666", color: "#ffffff", borderRadius: '30px' }}
									/>
								) : (
									<Avatar
										image={imageUrl}
										size="small"
										shape="circle"
										imageAlt="profile pic"
										style={{ borderRadius: '30px' }}
									/>
								)}
							</>
						}
					</button>
					<ul className="fadeInDown user-profile-content">
						{!defMembership || !profile ?
							<div className="grid formgrid">
								<div className="field col-12 md:col-6">
									<Skeleton height="2rem" className="mb-2"></Skeleton>
									<Skeleton className="mb-2"></Skeleton>
									<Skeleton className="mb-2"></Skeleton>
									<Skeleton className="mb-2"></Skeleton>
									<Skeleton className="mb-2"></Skeleton>
									<Skeleton className="mb-2"></Skeleton>
								</div>
							</div>
							:
							<div>
								<ul className="user-profile-items">
									<li>
										<button className="p-link">
											<span>{t("business.organisation_type")}:
												<b className="business-info">
													{t(`business.${type.toLowerCase()}`)}
												</b>
											</span>
										</button>
									</li>
									<li>
										<button className="p-link">
											<span>
												{t("business.account_purpose")}:
												<b className="business-info">
													{t(`business.${purpose.toLowerCase()}`)}
												</b>
											</span>
										</button>
									</li>
								</ul>
								<hr />
								<ul className="user-profile-items">
									{defMembership && (
										<div className="colleague-list-item">
											<div className="surface-card profile p-2">
												<div className="flex surface-border pb-4">
													<img src={imageUrl ? imageUrl : defaultAvatar} className="mr-1" style={{ width: "70px", height: "70px" }} alt="user pic" />
													<div className="flex flex-column align-items-start">
														<span className="user-info">{`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}</span>
														<span className="user-info">{email}</span>
														<span className={`role ${role.toLowerCase()}`}>{role}</span>
													</div>
												</div>
											</div>
										</div>
									)}
								</ul>
								<hr />
								{role === 'OWNER' ?
									menuItems.map((list, indx) => {
										return <ul className="user-profile-items" key={indx}>
											<li>
												<button className="p-link" onClick={() => list.onClick()}>
													<i className={list.icon} />
													<span>{list.name}</span>
												</button>
											</li>
										</ul>
									}) :
									menuItemsNoColleagues.map((list, indx) => {
										return <ul className="user-profile-items" key={indx}>
											<li>
												<button className="p-link" onClick={() => list.onClick()}>
													<i className={list.icon} />
													<span>{list.name}</span>
												</button>
											</li>
										</ul>
									})
								}
							</div>
						}
					</ul>
				</li>

				<TranslationMenu
					activeTopbarItem={activeTopbarItem}
					onTopbarItemClick={onTopbarItemClickIn}
					t={t}
				/>
				{/* <p>Business</p> */}
				<Business name={defMembership?.business.name} />
			</ul>
		</div>
	);
};

AppTopbar.defaultProps = {
	onMenuButtonClick: null,
	onTopbarMenuButtonClick: null,
	onTopbarItemClick: null,
	topbarMenuActive: false,
	activeTopbarItem: null,
	profileMode: null,
	horizontal: null,
};

AppTopbar.prototype = {
	onMenuButtonClick: PropTypes.func.isRequired,
	onTopbarMenuButtonClick: PropTypes.func.isRequired,
	onTopbarItemClick: PropTypes.func.isRequired,
	topbarMenuActive: PropTypes.bool.isRequired,
	activeTopbarItem: PropTypes.string,
	profileMode: PropTypes.string,
	horizontal: PropTypes.bool,
};

const Business = (props) => {
	return (
		<li
		>
			<p style={{ 'fontSize': '1.3em', fontWeight: "bold", color: "white", marginTop: "0.5em" }}>{props.name}</p>

		</li>
	);
};

const TranslationMenu = ({ activeTopbarItem, onTopbarItemClick, t }) => {
	const [lang] = useState({
		label: "Language",
		items: [
			{
				label: "English",
				tag: "en",
			},
			{
				label: "French",
				tag: "fr",
			},
		],
	});
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};
	return (
		<li
			className={classNames({
				"active-topmenuitem": activeTopbarItem === "language",
			})}
			onClick={(e) => onTopbarItemClick(e, "language")}
		>
			<button className={`lang p-link `}>
				<i className="pi pi-globe p-mt-2 p-ml-2" style={{ 'fontSize': '2em' }} ></i>

				<span className="topbar-item-name">{t(lang.label)}</span>
			</button>
			<ul className="fadeInDown">
				{lang.items.map(({ label, tag }, i) => (
					<li role="menuitem" key={i}>
						<button onClick={() => changeLanguage(tag)} className="p-link">
							<span>{t(label)}</span>
						</button>
					</li>
				))}
			</ul>
		</li>
	);
};
export default AppTopbar;